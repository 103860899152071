<template>
<b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <div>
    <!-- Miscellaneous Charts -->
    <b-row class="match-height">
      
      <b-col
        lg="12"
        cols="12"
      >
        <b-card
            no-body
            class="card-statistics"
        >
        <b-card-header>
            <b-card-title>Estadísticas</b-card-title>
            <b-card-text class="mr-25 mb-0">
            </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
            <b-row>
                <b-col
                v-for="item in statisticsItems"
                :key="item.icon"
                md="3"
                sm="6"
                class="mb-2 mb-md-0"
                :class="item.customClass"
                >
                <b-media no-body>
                    <b-media-aside

                    class="mr-2"
                    >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
                </b-media-aside>
                    <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ item.title }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ item.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
        <b-col
            lg="8"
            cols="12"
        >
            <b-card
                v-if="quizStats"
                no-body
                class="card-company-table"
            >
                <b-table
                    :items="quizStats"
                    responsive
                    :fields="fields"
                    class="mb-0"
                >
                <!-- company -->
                <template #cell(lesson)="data">
                    <div class="d-flex align-items-center">
                        <b-avatar
                            class="mr-1"
                            variant="light-primary"
                        >
                        <feather-icon
                            icon="BookOpenIcon"
                            class="text-primary"
                        />
                        </b-avatar>
                        <span class="font-weight-bolder mr-1">{{ data.item.quiz.lesson.name }}</span>
                        
                    </div>
                    <!--
                    <div class="d-flex align-items-center">
                        
                        <span >{{ data.item.quiz.lesson.name }}</span>
                    </div>
                    -->
                </template>
                <template #cell(quiz)="data">
                    <div class="d-flex align-items-center">
                        <b-avatar
                            class="mr-1"
                            variant="light-succ"
                        >
                            <feather-icon
                                            icon="ClipboardIcon"
                                            class="text-primary"
                                        />
                        </b-avatar>
                        <span class="font-weight-bolder mr-1">{{ data.item.quiz.name }}</span>
                        
                    </div>
                    <!--
                    <div class="d-flex align-items-center">
                    
                        <span>{{ data.item.quiz.name}}</span>
                    </div>
                    -->
                </template>
                <!-- category -->
                <template #cell(attempts)="data">
                    <div class="d-flex align-items-center">
                    
                        <span>{{ data.item.attempts }}</span>
                    </div>
                </template>

                <!-- views -->
                <template #cell(totalQuestion)="data">
                    <div class="d-flex flex-column">
                        <span>{{ data.item.questionCount }}</span>
                    </div>
                </template>

                <!-- revenue -->
                <template #cell(correctAnswer)="data">
                     <div class="d-flex flex-column">
                        <span>{{ data.item.answerCount }}</span>
                    </div>
                </template>
                
                </b-table>
            </b-card>
        </b-col>
      
      <b-col
        lg="4"
        cols="12"
      >
            <b-card
                no-body
                class="card-developer-meetup"
            >
                <div class="bg-light-primary rounded-top text-center">
                <b-img
                    :src="require('@/assets/images/illustration/email.svg')"
                    alt="Meeting Pic"
                    height="170"
                />
                </div>
            <b-card-body v-if="latestEvent">
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                <h6 class="mb-0">
                    
                    {{moment(latestEvent.start_date).format('ddd')}}
                </h6>
                <h3 class="mb-0">
                    {{moment(latestEvent.start_date).format('DD')}}
                </h3>
                </div>
                <div class="my-auto">
                <b-card-title class="mb-25">
                    {{latestEvent.title}}
                </b-card-title>
                <!--<b-card-text class="mb-0">
                    Meet world popular developers
                </b-card-text>-->
                    </div>
                </div>
                    <b-card-text class="mb-2">
                        {{latestEvent.description}}
                    </b-card-text>
      <!--/ metting header -->

      <!-- media -->
                    <b-media
                        no-body
                        class="mb-2"
                    >
                        <b-media-aside class="mr-1">
                        <b-avatar
                            rounded
                            variant="light-primary"
                            size="34"
                        >
                            <feather-icon
                            icon="CalendarIcon"
                            size="18"
                            />
                        </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                        <h6 class="mb-0">
                            {{moment(latestEvent.start_date).format('ddd, MMMM DD, YYYY')}}
                        </h6>
                        <small>{{moment(latestEvent.start_date).format('hh:mm:a')}} to {{moment(latestEvent.end_date).format('hh:mm:a')}}</small>
                        </b-media-body>
                    </b-media>
                        <b-media
                        no-body
                        class="mb-2"
                    >
                        <b-media-aside class="mr-1">
                        <b-avatar
                            rounded
                            variant="light-primary"
                            size="34"
                        >
                            <feather-icon
                            icon="MapPinIcon"
                            size="18"
                            />
                        </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                        <h6 class="mb-0">
                            {{latestEvent.location}}
                        </h6>
                        <small></small>
                        </b-media-body>
                    </b-media>
      <!-- avatar group -->
                </b-card-body>
                 <b-card-body v-else>
                    <h3>No Event</h3>
                </b-card-body>
            </b-card>
           
      </b-col>
    </b-row>
  </div>
  </b-overlay>
</template>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>

<script>

import { BRow, BCol, BCard, BDropdown,BTable, BDropdownItem, BOverlay, BButton, BCardHeader, BCardTitle, BCardText, BCardBody, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BCardSubTitle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DashboardService  from '@/services/dashboard.service.js';
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard, BCardHeader,
    BDropdown,
    BDropdownItem,
    BButton, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
    BImg,
    BCardSubTitle,
    BTable,
    BOverlay
  },
  directives: {
    Ripple,
    moment
  },
  data() {
    return {
        showOverlay: false,
    moment: moment,
    latestEvent: '',
    quizStats: [],
    statisticsItems: [
        {
          icon: 'ClipboardIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Test Totales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClipboardIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Intentos de Test',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Total Prácticas',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Prácticas Reservadas',
          customClass: '',
        },
      ],
      fields: [
        { key: 'lesson', label: 'Unidad' },
        { key: 'quiz', label: 'Test' },
        { key: 'attempts', label: 'Intentos' },
        { key: 'totalQuestion', label: 'Preguntas Totales' },
        { key: 'correctAnswer', label: 'Respuestas Correctas' },
      ],
    }
  },
  methods: {
    getStats() {
        this.showOverlay = true;
        DashboardService.studentDashboard().then(response => {
            this.statisticsItems[0].title = response.data.data.remaining_quiz
            this.statisticsItems[1].title = response.data.data.attempted_quiz
            this.statisticsItems[2].title = response.data.data.totalEvents
            this.statisticsItems[3].title = response.data.data.bookedEvents
            this.latestEvent = response.data.data.getLatestEvent
            this.quizStats = response.data.data.quizResults
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
  },
  created() {
    this.getStats();
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
