import { render, staticRenderFns } from "./student.vue?vue&type=template&id=49e68a29&scoped=true&"
import script from "./student.vue?vue&type=script&lang=js&"
export * from "./student.vue?vue&type=script&lang=js&"
import style0 from "./student.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./student.vue?vue&type=style&index=1&id=49e68a29&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e68a29",
  null
  
)

export default component.exports