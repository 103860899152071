import axiosIns from '@/libs/axios.js';

class DashboardService {
    adminDashboard(){
        return axiosIns.get(`/admin-dashboard`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    studentDashboard(){
        return axiosIns.get(`/student-dashboard`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new DashboardService();